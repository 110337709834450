import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "Quick Pick",
  code: "QuickPick"
};
export const _frontmatter = {
  "menuLabel": "Quick Pick",
  "sortOrder": 17.1
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Quick Pick`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-quickpick--default-story" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { QuickPick } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p>{`The QuickPick component is the same as the CannedParlay, just renamed.  CannedParlay still exists as an export but it's requested we use the QuickPick export for consistency.
Once we've removed references to CannedParlay, we will remove it from the package.  `}</p>
    <p>{`There are two variants to QuickPick, the default view and a minimal view which has lots of application as additional content in different templates and spaces.`}</p>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
  <AccessibilityTable.Principle principle="Percievable" status="ready">
   The Quick Pick component provides a visually distinguishable display for promoted same game parlays. It utilizes appropriate color contrast and styling to ensure the description, legs, and other elements are easily noticeable. The start time and other relevant information are displayed clearly for users to perceive.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Operable" status="ready">
    The Quick Pick component is operable through user interactions. Users can select a Quick Pick by clicking the selection button. The component also provides feedback to indicate the selection state, such as disabling the button or showing a lock icon when necessary
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Understandable" status="ready">
    The purpose of the Quick Pick component is clear through descriptive labels and intuitive design. Users can easily understand the canned parlay description, title, and legs. The display price and price are provided to convey the cost and potential winnings of the parlay. The component also offers a callback function, onClick, to handle user interactions.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Robust" status="ready">
    The Quick Pick component is designed to be compatible with assistive technologies, ensuring that screen readers can access the relevant information and convey the parlay details to visually impaired users. It functions consistently across different browsers and platforms. The component supports different variants, including the default view and a minimal view, providing flexibility for various use cases.
  </AccessibilityTable.Principle>
    </AccessibilityTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      